import React from 'react';
import { useRecordContext } from 'react-admin';

const GoogleMapsLinkField = () => {
  const record = useRecordContext();
  if (!record) return null;

  const { coordinates, address_first, address_city, state } = record;
  const latitude = coordinates?.latitude;
  const longitude = coordinates?.longitude;
  const address = address_first?.replace(/ /g, '+');
  const city = address_city?.replace(/ /g, '+');
  const stateName = state.name?.replace(/ /g, '+');

  if (!latitude || !longitude || !address_first || !city || !stateName) return null;

  const url = `https://www.google.com/maps/dir/${latitude},+${longitude}/${address},${city},${stateName}`;

  return (
    <a href={url} target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>
      Google Maps
    </a>
  );
};

export default GoogleMapsLinkField;